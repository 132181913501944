import { ReactNode, useState } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMContextMenu } from '../RMContextMenu';
import { RMDialog } from '../RMDialog';
import { RMText } from '../RMText/RMText';

import {
  BottomSheetContent,
  BottomSheetOption,
  CancelButton,
  Divider,
  Separator,
  TriggerWrapper,
} from './RMBottomSheet.styles';

export interface BottomSheetOption {
  label: string;
  value: string;
  disabled?: boolean;
  icon?: IconDefinition;
  destructive?: boolean;
  onSelect: (value: string) => void;
}

export interface RMBottomSheetProps {
  Trigger: ReactNode;
  options: BottomSheetOption[];
}

export function RMBottomSheet({ Trigger, options }: RMBottomSheetProps) {
  const isMobile = useIsMobileViewport();
  const [open, setOpen] = useState(false);

  if (isMobile == false) {
    return (
      <RMContextMenu.Root side="top" align="start" sideOffset={10} Trigger={Trigger}>
        {options.map((option) => (
          <RMContextMenu.Item
            key={option.value}
            label={option.label}
            value={option.value}
            rightIcon={option.icon}
            destructive={option.destructive}
            disabled={option.disabled}
            onClick={() => option.onSelect(option.value)}
          />
        ))}
      </RMContextMenu.Root>
    );
  }

  return (
    <>
      <TriggerWrapper onClick={() => setOpen(true)}>{Trigger}</TriggerWrapper>
      <RMDialog.Root open={open} onClose={() => setOpen(false)}>
        <RMDialog.Content>
          <BottomSheetContent>
            {options.map((option) => (
              <BottomSheetOption
                key={option.value}
                data-disabled={option.disabled}
                onClick={() => {
                  option.onSelect(option.value);
                  setOpen(false);
                }}
              >
                {option.icon && (
                  <FontAwesomeIcon
                    icon={option.icon}
                    size="sm"
                    color={option.destructive ? 'var(--danger)' : 'var(--primary)'}
                  />
                )}
                <RMText type="sans" size="xs" bold="medium" color={option.destructive ? 'danger' : 'primary'}>
                  {option.label}
                </RMText>
              </BottomSheetOption>
            ))}
            <Separator>
              <Divider />
            </Separator>
            <CancelButton onClick={() => setOpen(false)}>
              <RMText type="sans" size="xs" bold="medium" color="primary" align="center">
                Cancel
              </RMText>
            </CancelButton>
          </BottomSheetContent>
        </RMDialog.Content>
      </RMDialog.Root>
    </>
  );
}
