import { styled } from '@linaria/react';

export const TriggerWrapper = styled.button``;

export const BottomSheetContent = styled.div`
  padding: var(--spacing-xs) 0;
`;

export const BottomSheetOption = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  cursor: pointer;

  &[data-disabled='true'] {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const Separator = styled.div`
  padding: var(--spacing-2xs) var(--spacing-md);
`;

export const Divider = styled.div`
  border: 1px dashed rgba(213, 208, 195, 1);
`;

export const CancelButton = styled.div`
  display: flex;
  padding: var(--spacing-md);
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
