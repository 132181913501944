import { PropsWithChildren, ReactNode } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { ContextMenuContent } from './RMContextMenuRoot.styles';

type RMContextMenuRootProps = PropsWithChildren<{
  Trigger: ReactNode;
  onOpenChange?: (open: boolean) => void;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
}>;

export function RMContextMenuRoot({
  Trigger,
  onOpenChange,
  side = 'bottom',
  align = 'center',
  sideOffset = 0,
  children,
}: RMContextMenuRootProps) {
  return (
    <DropdownMenu.Root onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger style={{ width: 'fit-content' }}>{Trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          asChild
          side={side}
          sideOffset={sideOffset}
          align={align}
          style={{ zIndex: 1000 }}
          onCloseAutoFocus={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ContextMenuContent>{children}</ContextMenuContent>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
