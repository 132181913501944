import { styled } from '@linaria/react';

export const ContextMenuContent = styled.div`
  background-color: var(--surface-bright);
  width: 16rem;
  box-shadow: var(--elevation-3);
  border-radius: var(--radius-regular);
  border: 1px solid var(--border-hairline);
  overflow: hidden;
  padding: var(--spacing-xs) 0;

  &:hover,
  &:focus-visible {
    outline: none;
  }
`;
