import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { RMText, RMTextSize } from '../RMText/RMText';

import { ContextMenuItem, ContextMenuRightIcon, ContextMenuText } from './RMContextMenuItem.styles';

export interface RMContextMenuItemProps {
  label: string;
  value?: string;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  destructive?: boolean;
  variant?: 'default' | 'share';
  disabled?: boolean;

  onClick?: (value?: string) => void;
}

export function RMContextMenuItem({
  label,
  value,
  destructive,
  leftIcon,
  rightIcon,
  onClick,
  variant = 'default',
  disabled = false,
}: RMContextMenuItemProps) {
  return (
    <DropdownMenu.Item
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(value);
      }}
      asChild
    >
      <ContextMenuItem variant={variant} disabled={disabled}>
        {leftIcon && (
          <FontAwesomeIcon icon={leftIcon} size="sm" color={destructive ? 'var(--danger)' : 'var(--primary)'} />
        )}
        <ContextMenuText>
          <RMText type="sans" size="xs" bold="medium" color={destructive ? 'danger' : 'primary'}>
            {label}
          </RMText>
        </ContextMenuText>
        {rightIcon && (
          <ContextMenuRightIcon>
            <FontAwesomeIcon icon={rightIcon} size="sm" color={destructive ? 'var(--danger)' : 'var(--primary)'} />
          </ContextMenuRightIcon>
        )}
      </ContextMenuItem>
    </DropdownMenu.Item>
  );
}
