import { styled } from '@linaria/react';

export const ContextMenuItem = styled.div<{
  variant?: 'default' | 'share';
  disabled?: boolean;
}>`
  position: relative;
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-md);
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 100ms;
  }

  :hover {
    border: none !important;
    outline: none !important;
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :focus {
    outline: none;
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const ContextMenuRightIcon = styled.div`
  margin-left: auto;
`;

export const ContextMenuText = styled.div`
  flex-grow: 1;
`;
